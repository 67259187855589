.global-loading-wrapper {
    width: 100%;
    height: 100%;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    position: fixed;
    z-index: 99999;
    background-color: rgba( #000000, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
}