.main-content > .common-filter-table-pagination-page,
.main-content > .common-calendar-info-page,
.main-content > .ka-wrapper > .ka-content > .common-calendar-info-page,
.main-content
  > .ka-wrapper
  > .ka-content
  > .common-filter-table-pagination-page {
  width: 100%;
  height: 100%;
  display: flex;
  > .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
  }
}
