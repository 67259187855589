.page {
  height: 100%;
  width: 100%;

  :global(> .ant-space-item:nth-child(2)) {
    flex: 1;
    overflow: hidden;
  }
  .content {
    display: flex;
    height: 100%;
    width: 100%;
    flex: 1;
    .left {
      flex: 1;
    }
    .right {
      flex: 1;
    }
    .middle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
    }
    :global(.ant-card) {
        height: 100%;
        display: flex;
        flex-direction: column;
        
    }
    :global(.ant-card .ant-card-body) {
        flex: 1;
        height: 100%;
        overflow: hidden;
    }
    :global(.ant-card .ant-card-body .ant-list) {
        height: 100%;
        // height: 300px;
        // max-height: 80%;
        // max-height: 40vh;
        display: flex;
        flex-direction: column;
    }
    :global(.ant-card .ant-card-body .ant-list .ant-spin-nested-loading) {
        flex: 1;
        overflow: auto;
    }
    
    
    .listItem {
        justify-content: space-between;
        width: calc(100% - 28px);
        height: 48px;
        padding-left: 24px;
        border-bottom: 1px solid rgba(5, 5, 5, 0.06);
        .tips {
            color: #999999;
            font-size: 12px;
        }
    }
    :global(.ant-list-header >.ant-space) {
        // background-color: #;
        width: 100%;
    }
    :global(.ant-list-header > *) {
        height: 32px;
        border-bottom: none;
        padding-left: 0px;
    }
    :global(.ant-list-items) {
        max-height: 500px;
        overflow: auto;
    }
    :global(.ant-list-items .ant-space-item .ant-checkbox + span) {
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      width: 100%;
      display: -webkit-box;
    }
    
  }
}
