.container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .filter {
        // :global(.ant-form-item) {
            // width: 280px;
            // margin-bottom: 10px;
        // }
        // ant-col ant-form-item-label ant-form-item-label-wrap
        :global(.ant-col.ant-form-item-label.ant-form-item-label-wrap) {
            white-space: pre;
        }
        :global(.ant-space-item .ant-btn) {
            // margin-bottom: 10px;
        }
        :global(.ant-space) {
            margin-bottom: 8px;
        }
    }
    .calendar {
        flex: 1;
        overflow: auto;
        :global(.ant-picker-calendar) {
            padding-top: 10px;
        }
        :global(.ant-picker-calendar-header, .ant-picker-calendar-mode-switch) {
            display: none;
        }
        :global(.events) {
            padding-top: 20px;
    height: 100%;
    overflow: auto;
    margin-right: 12px;
        }    
        :global(.events > li .ant-badge) {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            flex-direction: row;
        }
        :global(.ant-picker-calendar-date-value) {
            display: none;
        }
        :global(.events .myDate) {
            position: absolute;
            right: 8px;
            top: 8px;
            color: #999;
        }
        :global(.ant-picker-cell-in-view .myDate) {
            color: #000;
        }
        :global(.ant-picker-cell-in-view .myDate.red) {
            color: red;
        }
        :global(.ant-picker-cell-in-view .ant-badge) {
            margin-right: 6px;
        }
        :global(.ant-picker-calendar-header) {
            display: none;
        }
    }
    
}
