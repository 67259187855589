.signup-page-filter {
    .student-select {
        width: 200px;
    }
    .student-grade-select {
        width: 120px;
    }
}
.signup-page-school-filter{
    margin-top: 16px;
    .school-select {
        width: 200px;
    }
}