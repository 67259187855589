.tags {
    height: 40px;   
    display: flex;
    align-items: center;
    overflow: auto;
    flex-direction: row;
    background-color: #fff;
    flex-shrink: 0;
    box-sizing: border-box;
}
.tagItem {
    border: 1px solid #EDEDED;
    height: 100%;
    max-width: 200px;
    flex-shrink: 0;
    // flex-grow: 1;
    min-width: 80px;
    line-height: 38px;
    // display: flex;
    flex-direction: row;
    
    padding: 0px 16px;
    align-items: center;
    background-color: #fff;
    align-items: center;
    
    a {
        width: 100%;
        display: flex;
        overflow: hidden;
    }
}

.link {
    
}
.title {
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-wrap: nowrap;
    overflow: hidden;
    color: #666666;
    
}
.close {
    color: #ff4d4f !important;
    margin-left: 2px;
}
.active {
    background-color: #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
        .title {
            color: #333;
        }
}