.layout {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.header {
  background-color: #fff;
  padding: 0px;
  // height: initial;
  display: flex;
  align-items: center;
  // margin-top: -12px;
  height: auto;
}
.content {
  background-color: #fff;
  overflow: hidden;
  flex: 1;
  width: 100%;
  position: relative;

  //   :global(.ant-spin-nested-loading) {
  //     height: 100%;
  //   }
  //   :global(.ant-spin-container) {
  //     height: 100%;
  //     display: flex;
  //     flex-direction: column;
  //     overflow: hidden;
  //   }
  //   :global(.ant-table) {
  //     flex: 1;
  //     overflow: hidden;
  //   }
  //   :global(.ant-table-container) {
  //     height: 100%;
  //   }
  //   :global(.ant-table-content) {
  //     height: 100%;
  //   }
  //   :global(.ant-table-content table) {
  //     height: 100%;
  //     overflow: hidden;
  //     display: flex;
  //     flex-direction: column;
  //   }
  //   :global(.ant-table-content table .ant-table-tbody) {
  //     flex: 1;
  //     overflow: auto;
  //   }
  //   :global(.ant-table-content table .ant-table-tbody tr) {
  //     width: 100%;
  //   }
  :global(.ant-table-pagination) {
    margin-bottom: 0px !important;
  }
}
.footer {
  background-color: #fff;
}
