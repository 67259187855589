.page {
    // width: 100%;
    // height: 100%;
    // display: flex;
    // flex: 1;
    .blockTitle {
        width: 100%;
        margin: 20px 0px;
        margin-bottom: 10px;
        height: 28px;
        line-height: 28px;
        border-left: 4px solid #F5821E;
        padding-left: 10px;
        font-size: 16px;
    }
    .blockItem {
        display: flex;
        flex-direction: row;
        height: 38px;
        // line-height: 34px;
        width: 100%;
        border: 1px solid #d9d9d9;
        align-items: center;
        box-sizing: border-box;
        .title {
            background-color: rgba(0, 0, 0, 0.04);
            width: 200px;
            height: 100%;
            // white-space: nowrap;
            display: flex;
            padding: 0px 10px;
            align-items: center;
            border-right: 1px solid #d9d9d9;
        }
        .content {
            flex: 1;
            padding: 0px 10px;
            display: flex;
            align-items: center;
        }
        .buttons {
            padding-right: 10px;
        }
    }
}
