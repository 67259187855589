.filter {
    // :global(.ant-form-item) {
        // width: 280px;
        // margin-bottom: 10px;
    // }
    // ant-col ant-form-item-label ant-form-item-label-wrap
    :global(.ant-col.ant-form-item-label.ant-form-item-label-wrap) {
        white-space: pre;
    }
    :global(.ant-space-item .ant-btn) {
        // margin-bottom: 10px;
    }
    :global(.ant-space) {
        margin-bottom: 8px;
    }
}