.common-form-info {
  .info-wrapper {
    padding-bottom: 16px;
    
    .info-title {
      width: 100%;
      margin: 20px 0px;
      margin-bottom: 10px;
      height: 28px;
      line-height: 28px;
      border-left: 4px solid #F5821E;
      padding-left: 10px;
      font-size: 16px;
    }
    .info-item {
      display: flex;
      flex-direction: row;
      min-height: 38px;
      // line-height: 34px;
      width: 100%;
      border: 1px solid #d9d9d9;
      align-items: center;
      box-sizing: border-box;
      background-color: rgba(0, 0, 0, 0.04);
      .title {
        // background-color: rgba(0, 0, 0, 0.04);
        width: 200px;
        height: 100%;
        // white-space: nowrap;
        display: flex;
        padding: 0px 10px;
        align-items: center;
        
        span {
        }
      }
      .content {
        flex: 1;
        padding: 0px 10px;
        display: flex;
        align-items: center;
        border-left: 1px solid #d9d9d9;
        min-height: 38px;
      }
      .buttons {
        padding-right: 10px;
      }
    }
  }
  .common-form-upload-item, .common-form-media-item {
    .common-form-upload-item__button, .common-form-media-item__button {
      position: relative;
      white-space: normal;
      min-height: 32px;
      height: auto;
      input[type="file"] {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0px;
        top: 0px;
        opacity: 0;
      }
    }
    .upload-list-item {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;

      .upload-list-item-name {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: underline;
      }
    }
  }
}
